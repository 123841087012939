import React from "react";

const Introduction = () => {
  return (
    <section className="relative flex flex-col items-center justify-center p-6 md:p-10 lg:p-20 mt-6 w-full min-h-[630px]">
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/e8945ad6956bf09a664656ce9cd885e376684c1d46bc55e7adf0d85d42bcb9db?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&"
        alt="Background"
        className="object-cover absolute inset-0 w-full h-full z-0"
      />
      <div className="relative z-10 text-center text-white max-w-3xl mx-auto">
        <div className="max-w-[70%] md:max-w-max lg:max-w-max mx-auto text-center md:text-left">
          <h1 className="mt-4 text-2xl md:text-5xl font-extrabold tracking-tight text-white uppercase leading-tight">
            <span className="block md:text-4xl">
              EXPERIENCE THE NEXT GEN WELLNESS WITH
            </span>
            <span className="block text-4xl text-orange-500 font-black">
              TECHNOLOGY
            </span>
          </h1>
          <p className="mt-6 text-base md:text-lg leading-8 text-gray-400">
            Our comprehensive suite of tools simplifies fitness and wellness
            management, enhances personal training, and empowers members on
            their wellness journey.
          </p>
          <div className="flex justify-center items-center flex-col max-w-full md:flex-row md:max-w-[90%] gap-4 mt-8">
            <a
              href="#EnquiryForm"
              className="w-full max-w-[70%] md:w-auto px-6 py-3 bg-blue-600 rounded-lg text-white"
            >
              Free trial for 30 days
            </a>
            <a
              href="#CallToAction"
              className="flex justify-center max-w-[70%] items-center mt-4 md:mt-0 w-full md:w-auto px-6 py-3 bg-gray-700 rounded-lg text-white"
            >
              <span className="mr-3">Get Demo</span>
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/6a51dc4298d193822a4514da1e8d5c3f687dfac4365cc7f501d596eab5176aab?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&"
                alt=""
                className="w-5 h-5"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Introduction;
