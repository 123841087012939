import React, { useState, useEffect } from "react";
import {
  CallToAction,
  OurFeatures,
  NavigationHeader,
  Introduction,
  BlogPosts,
  AllFaqs,
  Enquiry,
  SectionFooter,
} from "./components";

// const BlogPost = ({ image, title, description }) => (
//   <article className="flex flex-col grow max-md:mt-10">
//     <img
//       loading="lazy"
//       src={image}
//       alt={title}
//       className="w-full aspect-[0.85]"
//     />
//     <h3 className="mt-4 text-xl font-medium text-white">{title}</h3>
//     <p className="mt-2.5 text-base leading-6 text-white">
//       {description}
//       <span className="font-medium text-blue-600">Read more..</span>
//     </p>
//   </article>
// );

const FAQItem = ({ question, answer, isOpen, toggleOpen }) => (
  <>
    <div className="flex gap-0 py-6 text-white max-md:flex-wrap">
      <h3 className="flex-1 max-md:max-w-full">{question}</h3>
      <img
        loading="lazy"
        src={
          isOpen
            ? "https://cdn.builder.io/api/v1/image/assets/TEMP/34df55284fa13ab64505c6a1492338958845dc5918d9a00664e432bf0ddec330?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&"
            : "https://cdn.builder.io/api/v1/image/assets/TEMP/214d251dc7abe7719ef34f073ff68b2283010b4c9b83571d6c2505d8a255a569?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&"
        }
        alt={isOpen ? "Collapse" : "Expand"}
        className="shrink-0 self-start w-4 aspect-square"
        onClick={() => toggleOpen()}
      />
    </div>
    {isOpen && (
      <div className="mt-5 text-base leading-6 max-md:max-w-full">{answer}</div>
    )}
    <div className="shrink-0 mt-5 h-px bg-gray-700 max-md:max-w-full" />
  </>
);

function MyComponent() {
  // const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  // const [activeAccordion, setActiveAccordion] = useState(null);

  // const features = [
  //   {
  //     icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/c38af0f8d4ee808ce5509195f53a0d9ce5613a2a4861d9eadca11ce4b8383a9d?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&",
  //     title: "Billing and Finance Management",
  //     description:
  //       "Effortlessly manage members payments, making it convenient for both owners and members.",
  //   },
  //   {
  //     icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/8e6b66bad7da47c60d5124459370f054116f13637f8a2278e4c971c28d63b465?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&",
  //     title: "Marketing",
  //     description:
  //       "Run effective marketing campaigns to attract new members and keep existing ones engaged.",
  //   },
  //   {
  //     icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/7fc547796cfd6462dc7c793b9d4af7a4983a102ffdc31612288cc21833e0cbfb?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&",
  //     title: "Biometric Integration",
  //     description:
  //       "Restrict entry to only active members, enhancing center security and member exclusivity.",
  //   },
  //   {
  //     icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/198e021bc6d6070634741740f4786b1083c3e2c6750099f0d2d172ae040553e6?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&",
  //     title: "Attendance Tracking",
  //     description:
  //       "Keep an eye on member and staff attendance in real-time to promote accountability and engagement.",
  //   },
  //   {
  //     icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/938f7d96ba0f41caef4cd73114aa69806e0a3808cae53f78ea6d85999eac9b76?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&",
  //     title: "Lead management",
  //     description:
  //       "Capture, Categorize and Convert leads into paying members, maximizing your centre's growth.",
  //   },
  //   {
  //     icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/743d19076481670456e0c919e0e51ae4c7f401cf3713660841ed8be7113a6bb1?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&",
  //     title: "Multichannel Notifications",
  //     description:
  //       "Send timely reminders, invoices, and notifications through WhatsApp, SMS, and email.",
  //   },
  //   {
  //     icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/9920f516a4a1cd2877cf1ac1ee5f1995bc433fefb546cec0ec3d0aefbb401be0?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&",
  //     title: "Follow-Up Feature",
  //     description:
  //       "Provide support for effective follow-up actions, nurturing leads and maintaining strong member relationships.",
  //   },
  //   {
  //     icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/3de047d45c2e3ef61ce1cf95a2cce7f75e438c5aeaa2e9bd83e75ffa8412a45e?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&",
  //     title: "Analytics Reports",
  //     description:
  //       "Generate insightful reports and analytics on member attendance, financial performance, and engagement.",
  //   },
  // ];

  // const blogPosts = [
  //   {
  //     image:
  //       "https://cdn.builder.io/api/v1/image/assets/TEMP/49ab15bdc1fb55c2a5bf0b1a341ba84cca894bebbbf57420cf9886f57ab62009?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&",
  //     title: "Importance of HIIT",
  //     description:
  //       "Understand the role of HIIT in the world of fitness and how you can maximise the workout.",
  //   },
  //   {
  //     image:
  //       "https://cdn.builder.io/api/v1/image/assets/TEMP/3fa4b99bcb08cff43752e29094b5b882dcba485e5a8ec31acac84590838f587c?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&",
  //     title: "How to stay motivated everyday",
  //     description:
  //       "Learn to stay motivated throughout the day and know how to make yourself show up at the gym.",
  //   },
  //   {
  //     image:
  //       "https://cdn.builder.io/api/v1/image/assets/TEMP/1fdac977beb945d84244aa0af67a7276232916030be5c73ad69b85d0318dcc13?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&",
  //     title: "Fitness by sports training",
  //     description:
  //       "How sports changed the world of fitness and impacted our outlook on fitness and fitness regimes.",
  //   },
  // ];

  // const faqs = [
  //   {
  //     question: "Can I use wellzy in open-source projects?",
  //     answer:
  //       "Generally, it is accepted to use Wellzy in open-source projects, as long as it is not a UI library, a theme, a template, a page-builder that would be considered as an alternative to FlowBite itself. With that being said, feel free to use this design kit for your open-source projects. Find out more information by reading the license.",
  //     isOpen: true,
  //   },
  //   {
  //     question: "Where can I access my download files?",
  //     answer: "",
  //     isOpen: false,
  //   },
  //   {
  //     question: "Can I use Wellzy for commercial purposes?",
  //     answer: "",
  //     isOpen: false,
  //   },
  //   {
  //     question: "What about browser support?",
  //     answer: "",
  //     isOpen: false,
  //   },
  // ];

  // const toggleFAQ = (index) => {
  //   setActiveAccordion(activeAccordion === index ? null : index);
  // };

  // useEffect(() => {
  //   const handleResize = () => {
  //     if (window.innerWidth > 768) {
  //       setMobileMenuOpen(false);
  //     }
  //   };

  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);

  return (
    <div className="pt-6 pb-16 bg-zinc-950">
      {/* <header className="flex flex-col md:flex-row items-center justify-between w-full px-5 font-medium text-white leading-[150%] max-md:flex-wrap">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/2d6e949084b30444ef0f20342cecdc6cf1a8641b361b03c9fd4b13eaeea69778?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&"
          alt="Wellzy Logo"
          className="shrink-0 aspect-[2.13] w-[85px] mb-4 md:mb-0"
        />
        <nav className="hidden md:flex gap-5 my-auto">
          <a href="#features" className="text-white">
            Features
          </a>
          <a href="#benefits" className="text-white">
            Benefits
          </a>
          <a href="#blog" className="text-white">
            Blog
          </a>
          <a href="#about" className="text-white">
            About Us
          </a>
          <button className="px-3 py-2 text-sm bg-blue-600 rounded-lg">
            Contact Us
          </button>
        </nav>
        <div className="md:hidden">
          <button
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            className="text-white focus:outline-none"
          >
            <svg
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d={
                  mobileMenuOpen
                    ? "M6 18L18 6M6 6l12 12"
                    : "M4 6h16M4 12h16M4 18h16"
                }
              />
            </svg>
          </button>
        </div>
      </header>
      {mobileMenuOpen && (
        <nav className="md:hidden w-full bg-zinc-900 p-5">
          <a href="#features" className="block py-2 text-white">
            Features
          </a>
          <a href="#benefits" className="block py-2 text-white">
            Benefits
          </a>
          <a href="#blog" className="block py-2 text-white">
            Blog
          </a>
          <a href="#about" className="block py-2 text-white">
            About Us
          </a>
          <button className="mt-4 w-full px-3 py-2 text-sm bg-blue-600 rounded-lg text-white">
            Contact Us
          </button>
        </nav>
      )} */}
      <NavigationHeader />
      <main>
        {/* <section className="relative flex flex-col items-center justify-center p-6 md:p-10 lg:p-20 mt-6 w-full min-h-[630px]">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/e8945ad6956bf09a664656ce9cd885e376684c1d46bc55e7adf0d85d42bcb9db?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&"
            alt="Background"
            className="object-cover absolute inset-0 w-full h-full z-0"
          />
          <div className="relative z-10 text-center text-white max-w-3xl mx-auto">
            <div className="max-w-[70%] md:max-w-max lg:max-w-max mx-auto text-center md:text-left">
              <h1 className="mt-4 text-2xl md:text-5xl font-extrabold tracking-tight text-white uppercase leading-tight">
                <span className="block md:text-4xl">
                  EXPERIENCE THE NEXT GEN WELLNESS WITH
                </span>
                <span className="block text-4xl text-orange-500 font-black">
                  TECHNOLOGY
                </span>
              </h1>
              <p className="mt-6 text-base md:text-lg leading-8 text-gray-400">
                Our comprehensive suite of tools simplifies fitness and wellness
                management, enhances personal training, and empowers members on
                their wellness journey.
              </p>
              <div className="flex justify-center items-center flex-col max-w-full md:flex-row md:max-w-[90%] gap-4 mt-8">
                <button className="w-full max-w-[70%] md:w-auto px-6 py-3 bg-blue-600 rounded-lg text-white">
                  Free trial for 30 days
                </button>
                <button className="flex justify-center max-w-[70%] items-center mt-4 md:mt-0 w-full md:w-auto px-6 py-3 bg-gray-700 rounded-lg text-white">
                  <span className="mr-3">Get Demo</span>
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/6a51dc4298d193822a4514da1e8d5c3f687dfac4365cc7f501d596eab5176aab?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&"
                    alt=""
                    className="w-5 h-5"
                  />
                </button>
              </div>
            </div>
          </div>
        </section> */}
        <div id="IntroductionSection">
          <Introduction />
        </div>

        <section id="FeaturesSection">
          <OurFeatures />
        </section>

        <section
          id="BlogsSection"
          className="flex flex-col items-center self-stretch p-10 md:p-20 w-full bg-black max-md:max-w-full"
        >
          {/* <div className="flex flex-col items-center w-full max-w-screen-lg max-md:max-w-full">
            <h2 className="text-5xl font-medium tracking-tight text-center text-white max-md:max-w-full max-md:text-4xl">
              Dive into the world of{" "}
              <span className="text-orange-500">Wellzy</span>
            </h2>
            <div className="flex gap-3 mt-4 text-lg font-semibold leading-7 text-center text-blue-600">
              <div>Check out our fitness blogs</div>
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/d553443c2192ca679ff45a7ad0283a2b57d333e9579ccacba5c297c0ec9e24cd?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&"
                className="shrink-0 my-auto w-5 aspect-square"
                alt=""
              />
            </div>
            <div className="grid grid-cols-3 gap-5 mt-12 max-md:grid-cols-1 max-md:mt-10 max-md:max-w-full">
              {blogPosts.map((post, index) => (
                <BlogPost key={index} {...post} />
              ))}
            </div>
          </div> */}
          <BlogPosts />
        </section>

        {/* <section className="flex flex-col items-center self-stretch p-10 md:p-20 w-full bg-black max-md:max-w-full">
          <h2 className="text-4xl font-medium tracking-tight text-center text-orange-500 max-md:max-w-full max-md:text-3xl">
            Frequently asked questions
          </h2>
          <div className="flex flex-col mt-8 max-w-full text-lg font-medium leading-5 text-gray-400 bg-zinc-950 w-[695px] max-md:w-full">
            {faqs.map((faq, index) => (
              <FAQItem
                key={index}
                {...faq}
                isOpen={activeAccordion === index}
                toggleOpen={() => toggleFAQ(index)}
              />
            ))}
          </div>
        </section> */}

        <section id="FAQsSection">
          <AllFaqs />
        </section>

        <section id="CallToAction">
          <CallToAction />
        </section>

        {/* <section className="flex overflow-hidden relative flex-col justify-center items-center self-stretch px-16 py-11 w-full text-base min-h-[584px] max-md:px-5 max-md:max-w-full">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/66bfa86ba93d73c87882db1dd03cccf6af258d4af3dfb39faac15267633dec6d?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&"
            className="object-cover absolute inset-0 size-full"
            alt="Background"
          />
          <div className="flex relative flex-col max-w-full w-[768px]">
            <h2 className="text-4xl font-medium tracking-tight text-center text-orange-500 max-md:max-w-full max-md:text-3xl">
              Join us<span className="text-orange-500"> today</span>
            </h2>
            <form className="mt-4">
              <div className="flex gap-5 leading-[150%] max-md:flex-wrap">
                <div className="flex flex-col flex-1">
                  <label
                    htmlFor="firstName"
                    className="font-medium text-zinc-100"
                  >
                    First name
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    className="justify-center px-4 py-3.5 mt-2 text-gray-400 whitespace-nowrap rounded-lg border border-gray-600 border-solid bg-zinc-100"
                    placeholder="Bhaskar"
                  />
                </div>
                <div className="flex flex-col flex-1">
                  <label
                    htmlFor="lastName"
                    className="font-medium text-zinc-100"
                  >
                    Last name
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    className="justify-center px-4 py-3.5 mt-2 text-gray-400 whitespace-nowrap rounded-lg border border-gray-600 border-solid bg-zinc-100"
                    placeholder="Kumar"
                  />
                </div>
              </div>
              <div className="flex gap-5 mt-6 leading-[150%] max-md:flex-wrap">
                <div className="flex flex-col flex-1">
                  <label htmlFor="phone" className="font-medium text-zinc-100">
                    Phone number
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    className="justify-center px-4 py-3.5 mt-2 text-gray-400 rounded-lg border border-gray-600 border-solid bg-zinc-100"
                    placeholder="+91 8123783949"
                  />
                </div>
                <div className="flex flex-col flex-1 whitespace-nowrap">
                  <label htmlFor="email" className="font-medium text-zinc-100">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    className="justify-center px-4 py-3.5 mt-2 text-gray-400 rounded-lg border border-gray-600 border-solid bg-zinc-100"
                    placeholder="Bhaskar@example.com"
                  />
                </div>
              </div>
              <div className="mt-6">
                <label
                  htmlFor="message"
                  className="font-medium leading-[150%] text-zinc-100"
                >
                  Your message
                </label>
                <textarea
                  id="message"
                  className="justify-center px-5 py-3.5 mt-2.5 w-full text-gray-400 rounded-lg border border-gray-600 border-solid bg-zinc-100 leading-[150%]"
                  placeholder="Please contact me with the payment plans for the software."
                  rows="4"
                ></textarea>
              </div>
              <button
                type="submit"
                className="justify-center self-end px-5 py-3 mt-14 font-medium bg-blue-600 rounded-lg leading-[150%] text-zinc-100 max-md:mt-10 max-md:w-full"
              >
                Send message
              </button>
            </form>
          </div>
        </section> */}

        <section id="EnquiryForm">
          <Enquiry />
        </section>

        {/* <footer className="flex flex-col items-center justify-center p-8 bg-black max-md:p-5">
          <div className="grid grid-cols-4 gap-5 max-md:grid-cols-1 max-md:gap-0">
            <div className="flex flex-col items-start px-5 pb-5 max-md:mt-10">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/9bb1fb466a7a90aebee6a6dd99c8192fdf0618b7562f671373ac0172d8b40129?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&"
                className="aspect-[2.13] w-[85px]"
                alt="Wellzy Logo"
              />
              <p className="self-stretch mt-5 text-base leading-6 text-gray-400">
                Revitalize your wellness journey with innovative tools for a
                personalized fitness experience. This approach guides you toward
                a healthier, more empowered self.
              </p>
              <div className="flex gap-5 justify-center mt-5">
                <a href="#" aria-label="Facebook">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/970c1826ff77acd40445429b3d798f3ccb78cc11fb52f99be06b2b339da8e3f5?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&"
                    className="shrink-0 w-6 aspect-square"
                    alt="Facebook"
                  />
                </a>
                <a href="#" aria-label="Twitter">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/f774a2326834c7875785963f23186e52b6d002baa54bab0d000032c704657b9d?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&"
                    className="shrink-0 w-6 aspect-square"
                    alt="Twitter"
                  />
                </a>
                <a href="#" aria-label="Instagram">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/64d19e1cc189cc7b2e3df3347e3717171a1fc01c050a7ab167b876e5c673e7be?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&"
                    className="shrink-0 my-auto aspect-square w-[18px]"
                    alt="Instagram"
                  />
                </a>
              </div>
            </div>
            <div className="flex flex-col text-base leading-6 text-gray-400 max-md:mt-8">
              <h3 className="text-sm font-semibold text-white uppercase">
                COMPANY
              </h3>
              <a href="#features" className="mt-4">
                Features
              </a>
              <a href="#benefits" className="mt-4">
                Benefits
              </a>
              <a href="#blog" className="mt-4">
                Blog
              </a>
              <a href="#join-us" className="mt-4">
                Join Us
              </a>
            </div>
            <div className="flex flex-col text-base leading-6 text-gray-400 max-md:mt-8">
              <h3 className="text-sm font-semibold text-white uppercase">
                CONTACT DETAILS
              </h3>
              <p className="mt-4">Email: info@irudra.in</p>
              <p className="mt-4">Phone: +91 80 49605900</p>
            </div>
            <div className="flex flex-col text-base leading-6 text-gray-400 max-md:mt-8">
              <h3 className="text-sm font-semibold text-white uppercase">
                LEGAL
              </h3>
              <a href="#privacy" className="mt-4">
                Privacy Policy
              </a>
              <a href="#terms" className="mt-4">
                Terms & Conditions
              </a>
            </div>
          </div>
        </footer> */}
        <section id="FooterSection">
          <SectionFooter />
        </section>
      </main>
    </div>
  );
}

export default MyComponent;
