import { useState } from "react";
import { toast } from "react-toastify";
import images from "../assets/images";
import { customAxios } from "../utils/axios/axios";
import { WEBSITE } from "../utils/axios/pathUrls";
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from "../utils/constants/messages";
import { ACTION_BUTTONS } from "../utils/constants/keywords";
import useForms from "../hooks/useForm";
import { NEWS_LETTER_SECTION } from "../utils/constants/sectionLabels";
import TermsOfUse from "./Legal/TermsOfUse";

export default function CallToAction() {
  const [openTerms, setOpenTerms] = useState(false);

  const initialValues = {
    contactPhoneNumber: "",
  };

  const onSubmit = async (data) => {
    try {
      await customAxios.post(WEBSITE.POST_DEMO_ENQUIRY, data);
      toast.success(SUCCESS_MESSAGES.ENQUIRY_CREATED);
      reset();
    } catch (error) {
      reset();
      toast.error(ERROR_MESSAGES.ENQUIRY_CREATION_ERROR);
    }
  };
  const { register, handleSubmit, errors, reset } = useForms(
    initialValues,
    onSubmit
  );

  return (
    <div className="overflow-hidden">
      <div className="mx-auto text-white">
        <div className="relative isolate overflow-hidden px-6 shadow-2xl py-[60px] bg-white">
          <div className="uppercase text-center text-white text-xl lg:text-[52px] font-poppins leading-normal lg:leading-[62.4px]">
            <div>
              An All-in-One Solution to
              <span className="text-[#F27A06]"> maximize</span>,
            </div>
            <div>
              <span className="text-[#F27A06]">organize </span>
              and digitize your Brand
            </div>
          </div>
          <p className="mx-auto max-w-xl text-center font-semibold text-[20px] leading-[30px] pt-[16px]">
            {NEWS_LETTER_SECTION.REQUESTING_DEMO}
          </p>
          <form className="mx-auto max-w-md" onSubmit={handleSubmit}>
            <div className="mt-[16px] flex relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="block h-6 w-6 text-[#9CA3AF] absolute top-3.5 left-3.5"
              >
                <path
                  fillRule="evenodd"
                  d="M2 3.5A1.5 1.5 0 0 1 3.5 2h1.148a1.5 1.5 0 0 1 1.465 1.175l.716 3.223a1.5 1.5 0 0 1-1.052 1.767l-.933.267c-.41.117-.643.555-.48.95a11.542 11.542 0 0 0 6.254 6.254c.395.163.833-.07.95-.48l.267-.933a1.5 1.5 0 0 1 1.767-1.052l3.223.716A1.5 1.5 0 0 1 18 15.352V16.5a1.5 1.5 0 0 1-1.5 1.5H15c-1.149 0-2.263-.15-3.326-.43A13.022 13.022 0 0 1 2.43 8.326 13.019 13.019 0 0 1 2 5V3.5Z"
                  clipRule="evenodd"
                />
              </svg>
              <input
                id="mobile-Number"
                name="contactPhoneNumber"
                maxLength={10}
                type="tel"
                required
                {...register("contactPhoneNumber", {
                  required: true,
                  pattern: /^\d{10}$/,
                })}
                className="min-w-0 flex-auto rounded-l-lg border-2 bg-[#F4F4F4] px-3.5 py-[12px] text-black placeholder-[#9CA3AF] placeholder:text-[16px] text-[16px] pl-14 sm:text-sm sm:leading-6"
                placeholder="Enter your number"
              />
              <button
                type="submit"
                className="flex-none rounded-r-lg bg-[#1C64F2] px-[20.5px] text-base font-normal shadow-sm hover:bg-black border-2 border-[#1C64F2]"
              >
                {ACTION_BUTTONS.SEND_DEMO_REQUEST}
              </button>
            </div>
            {errors.contactPhoneNumber && (
              <p className="text-red-500 absolute">
                {ERROR_MESSAGES.PHONE_NUMBER}
              </p>
            )}
          </form>
          <p className="mx-auto max-w-xl text-center text-lg leading-8 pt-[16px] text-gray-300">
            We care about the protection of data. Read our{" "}
            <a
              onClick={() => setOpenTerms(true)}
              href="#terms"
              className="text-[#F27A06] underline "
            >
              Privacy Policy & Terms Of Use{" "}
            </a>
            {openTerms && (
              <TermsOfUse openTerms={openTerms} setOpenTerms={setOpenTerms} />
            )}
          </p>
          <img
            className="absolute top-0 left-0 inset-0 h-full w-full object-cover -z-10"
            src={images.demoSectionImage}
            alt="Demo Section"
          />
        </div>
      </div>
    </div>
  );
}
