export const HERO_SECTION = {
  DESCRIPTION:
    " Our comprehensive suite of tools simplifies fitness and wellness management, enhances personal training, and empowers members on their wellness journey.",
};

export const FEATURE_SECTION = {
  TITILE: "Our features",
  DESCRIPTION:
    "Here we focus on markets where creativity and innovation can unlock long-term value and growth.",
  BILLING_AND_FINANCE_MANAGEMENT: "Billing and Finance Management",
  BILLING_AND_FINANCE_MANAGEMENT_DESCRIPTION:
    "Effortlessly manage members payments, making it convenient for both owners and members.",
  MARKETTING_MANAGEMENT: "Marketing",
  MARKETTING_MANAGEMENT_DESCRIPTION:
    "Run effective marketing campaigns to attract new members and keep existing ones engaged.",
  BIOMETIRC_INTEGRATION: "Biometric Integration",
  BIOMETIRC_INTEGRATION_DESCRIPTION:
    "Restrict entry to only active members, enhancing center security and member exclusivity.",
  ATTENDANCE_TRACKING: "Attendance Tracking",
  ATTENDANCE_TRACKING_DESCRIPTION:
    "Keep an eye on member and staff attendance in real-time to promote accountability and engagement.",
  LEAD_MANAGEMENT: "Lead management",
  LEAD_MANAGEMENT_DESCRIPTION:
    "Capture, Categorize and Convert leads into paying members, maximizing your centre's growth.",
  MULTICHANNEL_NOTIFICATION: "Multichannel Notifications",
  MULTICHANNEL_NOTIFICATION_DESCRIPTION:
    "Send timely reminders, invoices, and notifications through WhatsApp, SMS, and email.",
  FOLLOW_UP_FEATURE: "Follow-Up Feature",
  FOLLOW_UP_FEATURE_DESCRIPTION:
    "Provide support for effective follow-up actions, nurturing leads and maintaining strong member relationships.",
  ANALYTICS_REPORTS: "Analytics Reports",
  ANALYTICS_REPORTS_DESCRIPTION:
    "Generate insightful reports and analytics on member attendance, financial performance, and engagement.",
};

export const NEWS_LETTER_SECTION = {
  REQUESTING_DEMO: " Request for a demo and get 15 days free trail",
};

export const FOOTER_SECTION = {
  DESCRIPTION:
    "Revitalize your wellness journey with innovative tools for a personalized fitness experience. This approach guides you toward a healthier, more empowered self.",
  COMPANY_TITLE: "COMPANY",
  LEGAL_TITLE: "LEGAL",
  CONTACT_TITLE: "CONTACT DETAILS",
  COPYRIGHTS: " 2024, iFocus Systec Pvt Ltd. All rights reserved.",
};
