import { useState } from "react";

const FAQItem = ({ question, answer, isOpen, toggleOpen }) => (
  <>
    <div
      onClick={() => toggleOpen()}
      className="flex cursor-pointer gap-0 py-6 text-white max-md:flex-wrap"
    >
      <h3 className="flex-1 max-md:max-w-full">{question}</h3>
      <button>
        <img
          loading="lazy"
          src={
            isOpen
              ? "https://cdn.builder.io/api/v1/image/assets/TEMP/34df55284fa13ab64505c6a1492338958845dc5918d9a00664e432bf0ddec330?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&"
              : "https://cdn.builder.io/api/v1/image/assets/TEMP/214d251dc7abe7719ef34f073ff68b2283010b4c9b83571d6c2505d8a255a569?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&"
          }
          alt={isOpen ? "Collapse" : "Expand"}
          className="shrink-0 self-start w-4 aspect-square"
          onClick={() => toggleOpen()}
        />
      </button>
    </div>
    {isOpen && (
      <div className="mt-5 text-base leading-6 max-md:max-w-full">{answer}</div>
    )}
    <div className="shrink-0 mt-5 h-px bg-gray-700 max-md:max-w-full" />
  </>
);

const AllFaqs = () => {
  const [activeAccordion, setActiveAccordion] = useState(null);

  const toggleFAQ = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  const faqs = [
    {
      question: "How can WellZy benefit fitness trainers and businesses?",
      answer:
        " WellZy offers tailored solutions to streamline fitness center operations, manage memberships effectively, and enhance client engagement across multiple locations.",
      isOpen: true,
    },
    {
      question:
        "Can WellZy support marketing and member engagement for my fitness center?",
      answer:
        "Yes, WellZy offers features such as automated email campaigns, push notifications, and member feedback tools to enhance marketing efforts and keep members engaged and informed.",
      isOpen: false,
    },
    {
      question: "How does WellZy assist trainers in managing their clients?",
      answer:
        " WellZy allows trainers to create personalized workout plans, track client progress, schedule sessions, and communicate directly with clients through the platform.",
      isOpen: false,
    },
    {
      question:
        "Can WellZy integrate with existing systems in my fitness center?",
      answer:
        "Yes, WellZy is designed to integrate seamlessly with existing management and scheduling systems, allowing for a smooth transition and minimal disruption to your operations.",
      isOpen: false,
    },
    {
      question:
        "How can WellZy assist fitness center owners in adapting to changing market trends and member expectations?",
      answer:
        " WellZy provides actionable insights into member preferences and market trends through advanced analytics, helping fitness center owners make informed decisions and stay ahead in a competitive landscape.",
      isOpen: false,
    },
    {
      question:
        "Can WellZy help streamline administrative tasks for my fitness center?",
      answer:
        "Yes, WellZy simplifies administrative tasks through automated membership management, scheduling, and reporting, allowing owners to focus more on member experience and business growth.",
      isOpen: false,
    },
  ];
  return (
    <section className="flex flex-col items-center self-stretch p-10 md:p-20 w-full bg-black max-md:max-w-full">
      <h2 className="text-4xl font-medium tracking-tight text-center text-orange-500 max-md:max-w-full max-md:text-3xl">
        Frequently asked questions
      </h2>
      <div className="flex flex-col mt-8 max-w-full text-lg font-medium leading-5 text-gray-400 bg-zinc-950 w-[695px] max-md:w-full">
        {faqs.map((faq, index) => (
          <FAQItem
            key={index}
            {...faq}
            isOpen={activeAccordion === index}
            toggleOpen={() => toggleFAQ(index)}
          />
        ))}
      </div>
    </section>
  );
};

export default AllFaqs;
