import { useForm } from "react-hook-form";

const useForms = (initialValues, onSubmit) => {
  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    defaultValues: initialValues,
  });

  const handleReset = () => {
    reset(initialValues);
  };

  return {
    register,
    handleSubmit: handleSubmit(onSubmit),
    errors,
    reset: handleReset,
  };
};

export default useForms;
