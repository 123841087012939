export const SUCCESS_MESSAGES ={
    ENQUIRY_CREATED :"Message sent successfully",
}

export const ERROR_MESSAGES = {
    ENQUIRY_CREATION_ERROR : "Message not sent,please try again",
    FIRST_NAME : "First name is required",
    LAST_NAME : "Last name is required",
    PHONE_NUMBER : "Valid phone number is required",
    EMAIL : "Valid email is required",
    MESSAGE : "Message is required",
}
