"use client";
import { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   "pdfjs-dist/build/pdf.worker.min.mjs",
//   import.meta.url
// ).toString();
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export default function TermsOfUse({ openTerms, setOpenTerms }) {
  const [numPages, setNumPages] = useState(18);
  const [pageNumber, setPageNumber] = useState(1);

  // const pdfPath = `http://localhost:3000/TermsAndConditionsWellzy.pdf`;
  const pdfPath = `https://wellzy-website.s3.ap-south-1.amazonaws.com/Terms+Of+Use+and+Privacy+Policy.pdf`;

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <Dialog open={openTerms} onClose={setOpenTerms} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="overflow-y-scroll h-96">
              <Document
                file={pdfPath}
                onLoadSuccess={({ numPages }) => setNumPages(numPages)}
              >
                {Array.apply(null, Array(numPages))
                  .map((x, i) => i + 1)
                  .map((page) => (
                    <>
                      <Page
                        renderTextLayer={false}
                        renderAnnotationLayer={false}
                        customTextRenderer={false}
                        pageNumber={page}
                      />
                      <div className="border-t border-gray-300"></div>
                    </>
                  ))}
              </Document>
            </div>

            <div className="mt-5 flex items-center justify-center">
              <button
                type="button"
                data-autofocus
                onClick={() => setOpenTerms(false)}
                className="inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                Close
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
