import React from "react";
import RouteName from "../utils/constants/RouteNames";

const BlogPost = ({ image, title, description }) => (
  <article className="flex flex-col grow max-md:mt-10">
    <img
      loading="lazy"
      src={image}
      alt={title}
      className="w-full aspect-[0.85]"
    />
    <h3 className="mt-4 text-xl font-medium text-white">{title}</h3>
    <p className="mt-2.5 text-base leading-6 text-white">
      {description}
      <a
        href={RouteName.BLOG_WEBSITE}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="font-medium text-blue-600 pl-2"> Read more..</span>
      </a>
    </p>
  </article>
);

const BlogPosts = () => {
  const blogPosts = [
    {
      image:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/49ab15bdc1fb55c2a5bf0b1a341ba84cca894bebbbf57420cf9886f57ab62009?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&",
      title: "Importance of HIIT",
      description:
        "Understand the role of HIIT in the world of fitness and how you can maximise the workout.",
    },
    {
      image:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/3fa4b99bcb08cff43752e29094b5b882dcba485e5a8ec31acac84590838f587c?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&",
      title: "How to stay motivated everyday",
      description:
        "Learn to stay motivated throughout the day and know how to make yourself show up at the gym.",
    },
    {
      image:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/1fdac977beb945d84244aa0af67a7276232916030be5c73ad69b85d0318dcc13?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&",
      title: "Fitness by sports training",
      description:
        "How sports changed the world of fitness and impacted our outlook on fitness and fitness regimes.",
    },
  ];
  return (
    <div className="flex flex-col items-center w-full max-w-screen-lg max-md:max-w-full">
      <a>
        <h2 className="text-5xl font-medium tracking-tight text-center text-white max-md:max-w-full max-md:text-4xl">
          Dive into the world of <span className="text-orange-500">Wellzy</span>
        </h2>
      </a>
      <a
        href={RouteName.BLOG_WEBSITE}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="flex gap-3 mt-4 text-lg font-semibold leading-7 text-center text-blue-600">
          Check out our fitness blogs
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/d553443c2192ca679ff45a7ad0283a2b57d333e9579ccacba5c297c0ec9e24cd?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&"
            className="shrink-0 my-auto w-5 aspect-square"
            alt=""
          />
        </div>
      </a>
      <div className="grid grid-cols-3 gap-5 mt-12 max-md:grid-cols-1 max-md:mt-10 max-md:max-w-full">
        {blogPosts.map((post, index) => (
          <BlogPost key={index} {...post} />
        ))}
      </div>
    </div>
  );
};

export default BlogPosts;
