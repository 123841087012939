const RouteName = {
  FEATURE: "#feature",
  ABOUT_US: "#about_us",
  CONTACT_SECTIONS: "#contact_sections",
  LOGIN: "https://admin.wellzy.in/login",
  SIGNUP: "https://admin.wellzy.in/register",
  BLOG_WEBSITE: "https://wellzy-in.blogspot.com/2024/",
  FACEBOOK: "https://www.facebook.com/profile.php?id=61553956623627",
  INSTAGRAM: "https://www.instagram.com/wellzy.in/",
  LINKEDIN: " https://www.linkedin.com/company/irudra-in/posts/?feedView=all",
  X: "https://x.com/WellZy_in",
};

export default RouteName;
