import { toast } from "react-toastify";
import images from "../assets/images";
import { customAxios } from "../utils/axios/axios";
import { WEBSITE } from "../utils/axios/pathUrls";
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from "../utils/constants/messages";
import { ACTION_BUTTONS, FORM_LABELS } from "../utils/constants/keywords";
import useForms from "../hooks/useForm";

const Enquiry = () => {
  const initialValues = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    comments: "",
  };

  const onSubmit = async (data) => {
    try {
      const response = await customAxios.post(WEBSITE.POST_ENQUIRY, data);
      toast.success(SUCCESS_MESSAGES.ENQUIRY_CREATED);
      reset();
    } catch (error) {
      reset();
      toast.error(ERROR_MESSAGES.ENQUIRY_CREATION_ERROR);
    }
  };
  const { register, handleSubmit, errors, reset } = useForms(
    initialValues,
    onSubmit
  );

  return (
    <section className="flex overflow-hidden relative flex-col justify-center items-center self-stretch px-16 py-11 w-full text-base min-h-[584px] max-md:px-5 max-md:max-w-full">
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/66bfa86ba93d73c87882db1dd03cccf6af258d4af3dfb39faac15267633dec6d?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&"
        className="object-cover absolute inset-0 size-full"
        alt="Background"
      />
      <div className="flex relative flex-col max-w-full w-[768px]">
        <h2 className="text-4xl font-medium tracking-tight text-center text-orange-500 max-md:max-w-full max-md:text-3xl">
          Join us<span className="text-orange-500"> today</span>
        </h2>
        <form onSubmit={handleSubmit} method="POST" className="mt-4">
          <div className="flex gap-5 leading-[150%] max-md:flex-wrap">
            <div className="flex flex-col flex-1">
              <label htmlFor="firstName" className="font-medium text-zinc-100">
                First name
              </label>
              <input
                {...register("firstName", { required: true })}
                type="text"
                id="firstName"
                name="firstName"
                className="justify-center px-4 py-3.5 mt-2 text-gray-400 whitespace-nowrap rounded-lg border border-gray-600 border-solid bg-zinc-100"
                placeholder="Bhaskar"
              />
              {errors.firstName && (
                <p className="text-red-500">{ERROR_MESSAGES.FIRST_NAME}</p>
              )}
            </div>
            <div className="flex flex-col flex-1">
              <label htmlFor="lastName" className="font-medium text-zinc-100">
                Last name
              </label>
              <input
                {...register("lastName", { required: true })}
                type="text"
                id="lastName"
                name="lastName"
                className="justify-center px-4 py-3.5 mt-2 text-gray-400 whitespace-nowrap rounded-lg border border-gray-600 border-solid bg-zinc-100"
                placeholder="Kumar"
              />
              {errors.lastName && (
                <p className="text-red-500">{ERROR_MESSAGES.LAST_NAME}</p>
              )}
            </div>
          </div>
          <div className="flex gap-5 mt-6 leading-[150%] max-md:flex-wrap">
            <div className="flex flex-col flex-1">
              <label htmlFor="phone" className="font-medium text-zinc-100">
                Phone number
              </label>
              <input
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                maxLength={10}
                {...register("phoneNumber", {
                  required: true,
                  pattern: /^\d{10}$/,
                })}
                className="justify-center px-4 py-3.5 mt-2 text-gray-400 rounded-lg border border-gray-600 border-solid bg-zinc-100"
                placeholder="+91 8123783949"
              />
              {errors.phoneNumber && (
                <p className="text-red-500 ">{ERROR_MESSAGES.PHONE_NUMBER}</p>
              )}
            </div>
            <div className="flex flex-col flex-1 whitespace-nowrap">
              <label htmlFor="email" className="font-medium text-zinc-100">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                {...register("email", {
                  required: true,
                  pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
                })}
                className="justify-center px-4 py-3.5 mt-2 text-gray-400 rounded-lg border border-gray-600 border-solid bg-zinc-100"
                placeholder="Bhaskar@example.com"
              />
              {errors.email && (
                <p className="text-red-500">{ERROR_MESSAGES.EMAIL}</p>
              )}
            </div>
          </div>
          <div className="mt-6">
            <label
              htmlFor="message"
              className="font-medium leading-[150%] text-zinc-100"
            >
              Your message
            </label>
            <textarea
              id="comments"
              className="justify-center px-5 py-3.5 mt-2.5 w-full text-gray-400 rounded-lg border border-gray-600 border-solid bg-zinc-100 leading-[150%]"
              placeholder="Please contact me with the payment plans for the software."
              rows="4"
              {...register("comments", { required: true })}
              defaultValue={""}
            />
            {errors.messages && (
              <p className="text-red-500 absolute">{ERROR_MESSAGES.MESSAGE}</p>
            )}
          </div>
          <button
            type="submit"
            className="justify-center self-end px-5 py-3 mt-14 font-medium bg-blue-600 rounded-lg leading-[150%] text-zinc-100 max-md:mt-10 max-md:w-full"
          >
            Send message
          </button>
        </form>
      </div>
    </section>
  );
};

export default Enquiry;
