import { useState, useEffect } from "react";
import RouteName from "../utils/constants/RouteNames";

const NavigationHeader = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setMobileMenuOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div>
      <header className="flex flex-col md:flex-row items-center justify-between w-full px-5 font-medium text-white leading-[150%] max-md:flex-wrap">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/2d6e949084b30444ef0f20342cecdc6cf1a8641b361b03c9fd4b13eaeea69778?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&"
          alt="Wellzy Logo"
          className="shrink-0 aspect-[2.13] w-[85px] mb-4 md:mb-0"
        />
        <nav className="hidden md:flex gap-5 my-auto">
          <a
            href="#FeaturesSection"
            className="px-3 py-2 text-sm  bg-black rounded-lg  text-white shadow-sm hover:bg-[#1C64F2] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
          >
            Features
          </a>
          <a
            href="#BlogsSection"
            className="px-3 py-2 text-sm  bg-black rounded-lg  text-white shadow-sm hover:bg-[#1C64F2] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
          >
            Blog
          </a>
          <a
            href="#IntroductionSection"
            className="px-3 py-2 text-sm  bg-black rounded-lg  text-white shadow-sm hover:bg-[#1C64F2] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
          >
            About Us
          </a>
          <a
            href="#FooterSection"
            className="px-3 py-2 text-sm  bg-black rounded-lg  text-white shadow-sm hover:bg-[#1C64F2] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
          >
            Contact Us
          </a>
          <a
            href={RouteName.LOGIN}
            target="_blank"
            className="px-3 py-2 text-sm  bg-black rounded-lg  text-white shadow-sm hover:bg-[#1C64F2] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
          >
            Login
          </a>
          <a
            href={RouteName.SIGNUP}
            target="_blank"
            className="px-3 py-2 text-sm  bg-black rounded-lg  text-white shadow-sm hover:bg-[#1C64F2] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
          >
            Sign Up
          </a>
        </nav>
        <div className="md:hidden">
          <button
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            className="text-white focus:outline-none"
          >
            <svg
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d={
                  mobileMenuOpen
                    ? "M6 18L18 6M6 6l12 12"
                    : "M4 6h16M4 12h16M4 18h16"
                }
              />
            </svg>
          </button>
        </div>
      </header>
      {mobileMenuOpen && (
        <nav className="md:hidden w-full bg-zinc-900 p-5">
          <a href="#FeaturesSection" className="block px-3 py-2 text-white">
            Features
          </a>
          <a href="#BlogsSection" className="block px-3 py-2 text-white">
            Blog
          </a>
          <a href="#IntroductionSection" className="block px-3 py-2 text-white">
            About Us
          </a>
          <a
            href={RouteName.LOGIN}
            target="_blank"
            className="block px-3 py-2 text-white"
          >
            Login
          </a>
          <a
            href={RouteName.SIGNUP}
            target="_blank"
            // className="block mt-4 w-full px-3 py-2 text-sm rounded-lg text-white shadow-sm hover:bg-[#1C64F2] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
            className="block px-3 py-2 text-white"
          >
            Sign Up
          </a>
          <a href="#FooterSection" className="block px-3 py-2 text-white">
            Contact Us
          </a>
        </nav>
      )}
    </div>
  );
};

export default NavigationHeader;
