import { FEATURE_SECTION } from "../utils/constants/sectionLabels";

const features = [
  {
    name: FEATURE_SECTION.BILLING_AND_FINANCE_MANAGEMENT,
    description: FEATURE_SECTION.BILLING_AND_FINANCE_MANAGEMENT_DESCRIPTION,
    href: "#",
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/c38af0f8d4ee808ce5509195f53a0d9ce5613a2a4861d9eadca11ce4b8383a9d?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&",
  },
  {
    name: FEATURE_SECTION.MARKETTING_MANAGEMENT,
    description: FEATURE_SECTION.MARKETTING_MANAGEMENT_DESCRIPTION,
    href: "#",
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/8e6b66bad7da47c60d5124459370f054116f13637f8a2278e4c971c28d63b465?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&",
  },
  {
    name: FEATURE_SECTION.BIOMETIRC_INTEGRATION,
    description: FEATURE_SECTION.BIOMETIRC_INTEGRATION_DESCRIPTION,
    href: "#",
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/7fc547796cfd6462dc7c793b9d4af7a4983a102ffdc31612288cc21833e0cbfb?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&",
  },
  {
    name: FEATURE_SECTION.ATTENDANCE_TRACKING,
    description: FEATURE_SECTION.ATTENDANCE_TRACKING_DESCRIPTION,
    href: "#",
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/198e021bc6d6070634741740f4786b1083c3e2c6750099f0d2d172ae040553e6?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&",
  },
  {
    name: FEATURE_SECTION.LEAD_MANAGEMENT,
    description: FEATURE_SECTION.LEAD_MANAGEMENT_DESCRIPTION,
    href: "#",
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/938f7d96ba0f41caef4cd73114aa69806e0a3808cae53f78ea6d85999eac9b76?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&",
  },
  {
    name: FEATURE_SECTION.MULTICHANNEL_NOTIFICATION,
    description: FEATURE_SECTION.MULTICHANNEL_NOTIFICATION_DESCRIPTION,
    href: "#",
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/743d19076481670456e0c919e0e51ae4c7f401cf3713660841ed8be7113a6bb1?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&",
  },
  {
    name: FEATURE_SECTION.FOLLOW_UP_FEATURE,
    description: FEATURE_SECTION.FOLLOW_UP_FEATURE_DESCRIPTION,
    href: "#",
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/9920f516a4a1cd2877cf1ac1ee5f1995bc433fefb546cec0ec3d0aefbb401be0?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&",
  },
  {
    name: FEATURE_SECTION.ANALYTICS_REPORTS,
    description: FEATURE_SECTION.ANALYTICS_REPORTS_DESCRIPTION,
    href: "#",
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/3de047d45c2e3ef61ce1cf95a2cce7f75e438c5aeaa2e9bd83e75ffa8412a45e?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&",
  },
];

export default function Example() {
  return (
    <div className=" text-white mx-[20px] md:mx-[80px] my-[52px] md:my-[75px] ">
      <div>
        <div className="mx-auto lg:mx-0">
          <h2 className="text-[28px] text-center md:text-left font-extrabold    md:text-4xl">
            {FEATURE_SECTION.TITILE}
          </h2>
          <p className="mt-2 md:mt-4 text-center md:text-left text-[16px] md:text-xl font-normal  text-[#6B7280] leading-[24px] md:leading-[30px]">
            {FEATURE_SECTION.DESCRIPTION}
          </p>
        </div>
        <div className="mx-auto mt-7 md:mt-12  max-w-2xl sm:mt-20 lg:max-w-none">
          <dl className="grid  grid-cols-1 gap-x-8 gap-y-5 md:gap-y-8 lg:max-w-none lg:grid-cols-2">
            {features.map((feature) => (
              <div
                key={feature.name}
                className="flex flex-row gap-x-3 md:gap-x-5"
              >
                <div className=" flex min-h-[36px] min-w-[36px] h-[36px] w-[36px] md:min-h-12 md:min-w-12 md:h-12 md:w-12 items-center justify-center rounded-lg bg-[#F27A06]">
                  <img
                    className="h-5 w-5 md:h-6 md:w-6 text-white"
                    aria-hidden="true"
                    src={feature.icon}
                  />
                </div>
                <div>
                  <dt className="text-[16px] md:text-xl font-semibold  text-white">
                    {feature.name}
                  </dt>
                  <dd className="mt-1 md:mt-2 flex flex-auto flex-col text-[12px] md:text-base leading-[21px] md:leading-6 text-[#6B7280]">
                    <p className="flex-auto text-wrap">{feature.description}</p>
                  </dd>
                </div>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
