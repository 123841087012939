export const ACTION_BUTTONS={

    FEATURES : "Features",
    BLOG :"Blog",
    ABOUT_US : "About us",
    SIGN_UP : "Sign Up",
    LOGIN: "Login",
    FREE_TRAIL : "Free trial for 15 days",
    GET_DEMO : "Get Demo",
    SEND_DEMO_REQUEST : "Send",
    SEND_MESSAGE : "Send message",
    JOIN_US :"Join Us",
    PRIVAY_AND_POLICY :"Privacy Policy",
    TERMS_AND_CONDITION :"Terms & Conditions"
}

export const FORM_LABELS ={
    FIRST_NAME : "First name",
    LAST_NAME : "Last name",
    PHONE_NUMBER : "Phone number",
    EMAIL:"Email",
    YOUR_MESSAGE : "Your message"
}


