import { useState } from "react";
import RouteName from "../utils/constants/RouteNames";
import { FOOTER_SECTION } from "../utils/constants/sectionLabels";
import { TermsOfUse } from ".";

const SectionFooter = () => {
  const [openTerms, setOpenTerms] = useState(false);

  return (
    <footer className="flex flex-col items-center justify-center p-8 bg-black max-md:p-5">
      <div className="grid grid-cols-4 gap-5 max-md:grid-cols-1 max-md:gap-0">
        <div className="flex flex-col items-start px-5 pb-5 max-md:mt-10">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/9bb1fb466a7a90aebee6a6dd99c8192fdf0618b7562f671373ac0172d8b40129?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&"
            className="aspect-[2.13] w-[85px]"
            alt="Wellzy Logo"
          />
          <p className="self-stretch mt-5 text-base leading-6 text-gray-400">
            Revitalize your wellness journey with innovative tools for a
            personalized fitness experience. This approach guides you toward a
            healthier, more empowered self.
          </p>
          <div className="flex gap-5 justify-center mt-5">
            <a href={RouteName.X} target="_blank" aria-label="Twitter">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/970c1826ff77acd40445429b3d798f3ccb78cc11fb52f99be06b2b339da8e3f5?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&"
                className="shrink-0 w-6 aspect-square"
                alt="Twitter"
              />
            </a>
            <a href={RouteName.FACEBOOK} target="_blank" aria-label="Facebook">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/f774a2326834c7875785963f23186e52b6d002baa54bab0d000032c704657b9d?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&"
                className="shrink-0 w-6 aspect-square"
                alt="Facebook"
              />
            </a>
            <a
              href={RouteName.INSTAGRAM}
              target="_blank"
              aria-label="Instagram"
            >
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/64d19e1cc189cc7b2e3df3347e3717171a1fc01c050a7ab167b876e5c673e7be?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&"
                className="shrink-0 my-auto aspect-square w-[18px]"
                alt="Instagram"
              />
            </a>
          </div>
        </div>
        <div className="flex flex-col text-base leading-6 text-gray-400 max-md:mt-8">
          <h3 className="text-sm font-semibold text-white uppercase">
            COMPANY
          </h3>
          <a href="#FeaturesSection" className="mt-4">
            Features
          </a>
          <a href="#BlogsSection" className="mt-4">
            Blog
          </a>
          <a href="#EnquiryForm" className="mt-4">
            Join Us
          </a>
        </div>
        <div className="flex flex-col text-base leading-6 text-gray-400 max-md:mt-8">
          <h3 className="text-sm font-semibold text-white uppercase">
            CONTACT DETAILS
          </h3>
          <p className="mt-4">Email: info@irudra.in</p>
          <p className="mt-4">Phone: +91 80 49605900</p>
        </div>
        <div className="flex flex-col text-base leading-6 text-gray-400 max-md:mt-8">
          <h3 className="text-sm font-semibold text-white uppercase">LEGAL</h3>
          {/* <a href="#privacy" className="mt-4">
            Privacy Policy
          </a> */}

          <a onClick={() => setOpenTerms(true)} href="#terms" className="mt-4">
            Privacy Policy & Terms Of Use
          </a>
          {openTerms && (
            <TermsOfUse openTerms={openTerms} setOpenTerms={setOpenTerms} />
          )}
        </div>
      </div>
      <div className="mt-16 border-t border-[#374151] pt-8 sm:mt-20 lg:mt-24">
        <p className="text-base leading-6 font-normal text-center text-[#9CA3AF]">
          &copy;{FOOTER_SECTION.COPYRIGHTS}
        </p>
      </div>
    </footer>
  );
};

export default SectionFooter;
